import { GridColumnProps } from "@progress/kendo-vue-grid";

export const lessonColumns: Array<GridColumnProps> = [
    {
        field: "name",
        title: "Lesson Name"
    },
    {
        field: "itemNumber",
        title: "SKU"
    },
    {
        field: "sortOrder",
        title: "Sort Order"
    },
    {
        field: "edit",
        title: "Edit",
        width: 100,
        filterable: false,
        sortable: false
    },
    {
        title: "Delete",
        cell: "commandCell",
        filterable: false,
        sortable: false,
        width: 200
    }
];
