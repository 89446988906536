
import ApiGrid from "./ApiGrid.vue";
import { DialogVue3 } from "@progress/kendo-vue-dialogs/dist/npm/Dialog";
import QuestionForm from "./QuestionForm.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import { createUniqueUrl } from "@/helpers";
import { QuestionGridModel, QuestionModel } from "@/models";
import { routeNames } from "@/router";
import { restClient } from "@/store/actions";
import { ref, computed, defineComponent } from "vue";
import { questionColumns } from "@/columns";

export default defineComponent({
    components: {
        "api-grid": ApiGrid,
        "k-dialog": DialogVue3,
        "question-form": QuestionForm,
        "loading-indicator": LoadingIndicator
    },
    props: {
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        },
        quizId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const gridId = computed(() => `question-grid-${props.quizId}`);

        // see https://vuejs.org/guide/typescript/composition-api.html#typing-component-template-refs
        const grid = ref<InstanceType<typeof ApiGrid> | null>(null);

        const requestBody = ref<QuestionGridModel>({
            quizId: props.quizId
        });

        const question = ref<QuestionModel | undefined>();

        const visibleDialog = ref(false);
        const toggleDialog = (): void => {
            visibleDialog.value = !visibleDialog.value;

            // if we just closed the modal, refresh the grid
            if (!visibleDialog.value) {
                grid.value?.refresh();
            }
        };
        const modalTitle = computed(() =>
            question.value?.id && question.value.id > 0
                ? "Edit Question"
                : "Add Question"
        );

        const handleCreate = (): void => {
            question.value = undefined;

            toggleDialog();

            question.value = {
                id: 0,
                quizId: props.quizId,
                answerOptions: []
            };
        };

        const handleEdit = async (id: number): Promise<void> => {
            question.value = undefined;

            toggleDialog();

            const response = await restClient.getJson<QuestionModel>(
                `/api/admin/Question/${id}`
            );

            if (response.status !== 200) {
                toggleDialog();
            } else {
                question.value = response.data;
            }
        };

        return {
            routeNames,
            questionColumns,
            requestBody,
            visibleDialog,
            toggleDialog,
            question,
            handleCreate,
            handleEdit,
            createUniqueUrl,
            modalTitle,
            grid,
            gridId
        };
    }
});
