
import {
    GridPageChangeEvent,
    GridVue3,
    GridNoRecordsVue3,
    GridSortChangeEvent,
    GridColumnProps,
    GridFilterChangeEvent
} from "@progress/kendo-vue-grid";
import { defineComponent, onMounted, toRefs, PropType } from "vue";

import { AppActionTypes, restClient } from "@/store/actions";

import {
    toDataSourceRequestString,
    SortDescriptor
} from "@progress/kendo-data-query";

import DeleteCell from "./DeleteCell.vue";

import { ApiGridState, DataSourceResult } from "@/models";
import LoadingIndicator from "./LoadingIndicator.vue";
import { promptDelete } from "@/helpers";
import { AppMutationTypes, useStore } from "@/store";

export default defineComponent({
    components: {
        "grid": GridVue3,
        "grid-no-records": GridNoRecordsVue3,
        "delete-cell": DeleteCell,
        "loading-indicator": LoadingIndicator
    },

    props: {
        id: { type: String, required: true },

        /** Column definitions */
        columns: {
            type: Array as PropType<GridColumnProps[]>,
            default: () => [],
            required: true
        },

        /** API grid controller URL */
        url: { type: String, required: true },

        /** Default sorts to initialize in the grid */
        sort: { type: Array, default: () => [] },

        /** Filter for the data source request. Non-column specific. (ex: a parent ID for the grid to filter child entities for) */
        requestBody: {
            type: Object,
            required: false,
            default: null
        },

        /** Enables/disables kendo column filters (filters displayed below the column headers) */
        filterable: { type: Boolean, default: () => false }
    },

    setup(props) {
        const store = useStore();

        // Variables:

        if (!store.state.gridState[props.id]) {
            const gridState: ApiGridState = {
                id: props.id,
                // Row data:
                dataItems: null,
                // Data URL:
                url: props.url,
                // Paging
                pageable: {
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [1, 5, 10, 25, 100],
                    previousNext: true
                },
                skip: 0,
                take: 25,
                pageSize: 25,
                total: 0,
                sort: (props.sort as SortDescriptor[]) || [],
                filterable: props.filterable,
                filter: {
                    logic: "and",
                    filters: []
                },
                requestBody: props.requestBody || null
            };

            // Set the grid state and get the grid data
            store.dispatch(AppActionTypes.getGridData, gridState);
        }

        // Methods:

        const handleDelete = async (
            item: Record<string, string | number>
        ): Promise<void> => {
            if (await promptDelete("Are you sure you want to delete this?")) {
                const query = serialize(item);
                const request = toDataSourceRequestString(
                    store.state.gridState[props.id]
                );
                let url = `${props.url}?${query}&${request}`;

                const response = await restClient.delete<DataSourceResult>(url);

                if (response.status !== 200) {
                    return;
                }

                refresh();
            }
        };

        const refresh = async (): Promise<void> => {
            await store.dispatch(AppActionTypes.refreshGrid, props.id);
        };

        const pageChanged = (e: GridPageChangeEvent): void => {
            store.commit(AppMutationTypes.setGridPage, {
                id: props.id,
                event: e
            });

            refresh();
        };

        const sortChanged = (e: GridSortChangeEvent): void => {
            store.commit(AppMutationTypes.setGridSort, {
                id: props.id,
                event: e
            });

            refresh();
        };

        const filterChanged = (e: GridFilterChangeEvent): void => {
            store.commit(AppMutationTypes.setGridFilter, {
                id: props.id,
                event: e
            });

            refresh();
        };

        const serialize = (obj: Record<string, string | number>): string => {
            const str = [];
            for (let p in obj)
                if (Object.prototype.hasOwnProperty.call(obj, p)) {
                    str.push(
                        encodeURIComponent(p) + "=" + encodeURIComponent(obj[p])
                    );
                }

            return str.join("&");
        };

        // Lifecycle Events:

        onMounted(refresh);

        // Vue data:
        return {
            ...toRefs(store.state.gridState[props.id]),
            pageChanged,
            sortChanged,
            filterChanged,
            handleDelete,
            refresh
        };
    }
});
