import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("td", null, [
    _createElementVNode("button", {
      class: "btn btn-sm btn-danger k-grid-remove-command",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeHandler && _ctx.removeHandler(...args)))
    }, " Delete ")
  ]))
}