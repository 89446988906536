import { GridColumnProps } from "@progress/kendo-vue-grid";

export const lessonFileUploadColumns: Array<GridColumnProps> = [
    {
        field: "fileUploadTypeDisplayName",
        title: "File Type"
    },
    {
        field: "fileUrl",
        title: "File",
        filterable: false,
        sortable: false,
        width: 200
    },
    {
        field: "edit",
        title: "Edit",
        filterable: false,
        sortable: false,
        width: 100
    },
    {
        title: "Delete",
        cell: "commandCell",
        filterable: false,
        sortable: false,
        width: 200
    }
];
