
import { computed, defineComponent, PropType, reactive } from "vue";
import { Form, FormActions } from "vee-validate";
import {
    getModelStateFromResponse,
    hasErrors,
    ValFormInput
} from "@elite/validation";
import {
    formatDate,
    convertDecimalToWhole,
    convertWholeToDecimal,
    promptDelete
} from "@/helpers";
import { QuizModel } from "@/models";
import { routeNames } from "@/router";
import { useStore, AppGetterTypes } from "@/store";
import { restClient } from "@/store/actions";
import { useRouter } from "vue-router";

export default defineComponent({
    props: {
        quiz: {
            type: Object as PropType<QuizModel>,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        }
    },
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();

        const model: QuizModel = reactive(props.quiz);
        const rules = computed(
            () => store.getters[AppGetterTypes.validationRules]?.quizModel
        );

        const isNew = model.id === 0;
        const inserted = computed(() =>
            model.insertedOn ? formatDate(model.insertedOn) : "-"
        );
        const updated = computed(() =>
            model.updatedOn ? formatDate(model.updatedOn) : "-"
        );
        const quizLessonPath = {
            name: routeNames.editLesson,
            params: { courseId: props.courseId, lessonId: props.lessonId }
        };

        // Convert score from stored decimal value to whole number (e.g. 0.75 => 75)
        if (model.passingScore) {
            model.passingScore = convertDecimalToWhole(model.passingScore);
        }

        // Submit quiz data
        const onSubmit = async (
            values: QuizModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            const data = { ...model };

            // Convert passing score % back to decimal to be saved
            if (data.passingScore) {
                data.passingScore = convertWholeToDecimal(data.passingScore);
            }

            // Set sort order to null if it has been removed
            if (!data.sortOrder) {
                data.sortOrder = null;
            }

            // Save quiz data
            const response = isNew
                ? await restClient.postJson<QuizModel>("/api/admin/Quiz", data)
                : await restClient.putJson<QuizModel>("/api/admin/Quiz", data);

            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
                return;
            }

            router.push(quizLessonPath);
        };

        // Prompt user to delete lesson
        const handleDelete = async (): Promise<void> => {
            if (
                await promptDelete("Are you sure you want to delete the quiz?")
            ) {
                const response = await restClient.delete(
                    `/api/admin/Quiz/${model.id}`
                );

                if (hasErrors(response)) {
                    return;
                }

                router.push(quizLessonPath);
            }
        };

        return {
            onSubmit,
            model,
            rules,
            isNew,
            inserted,
            updated,
            quizLessonPath,
            handleDelete
        };
    }
});
