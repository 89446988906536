import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xl-6 offset-xl-3" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quiz_form = _resolveComponent("quiz-form")!
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_content_card = _resolveComponent("content-card")!
  const _component_question_grid = _resolveComponent("question-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_content_card, null, {
          body: _withCtx(() => [
            (_ctx.model?.id !== undefined)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.model.id > 0
                                        ? _ctx.model.name || `Quiz ${_ctx.model.id}`
                                        : "New Quiz"), 1),
                  _createVNode(_component_quiz_form, {
                    "course-id": _ctx.courseId,
                    "lesson-id": _ctx.lessonId,
                    "quiz-id": _ctx.quizId,
                    quiz: _ctx.model
                  }, null, 8, ["course-id", "lesson-id", "quiz-id", "quiz"])
                ]))
              : (_openBlock(), _createBlock(_component_loading_indicator, { key: 1 }))
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.model?.id && _ctx.model?.id > 0)
      ? (_openBlock(), _createBlock(_component_content_card, {
          key: 0,
          class: "mt-3"
        }, {
          body: _withCtx(() => [
            _createVNode(_component_question_grid, {
              "course-id": _ctx.courseId,
              "lesson-id": _ctx.lessonId,
              "quiz-id": _ctx.quizId
            }, null, 8, ["course-id", "lesson-id", "quiz-id"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}