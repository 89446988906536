import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "btn-group",
  role: "group"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  class: "btn-group",
  role: "group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.dataItem.inEdit)
    ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "btn btn-sm btn-secondary",
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.editHandler && _ctx.editHandler(...args)))
          }, " Edit "),
          _createElementVNode("button", {
            class: "btn btn-sm btn-danger",
            type: "button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeHandler && _ctx.removeHandler(...args)))
          }, " Delete ")
        ])
      ]))
    : (_openBlock(), _createElementBlock("td", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "btn btn-sm btn-primary",
            type: "button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addUpdateHandler && _ctx.addUpdateHandler(...args)))
          }, " Save "),
          _createElementVNode("button", {
            class: "btn btn-sm btn-gray",
            type: "button",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancelDiscardHandler && _ctx.cancelDiscardHandler(...args)))
          }, " Cancel ")
        ])
      ]))
}