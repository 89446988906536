
import { defineComponent, ref, computed } from "vue";

export default defineComponent({
    props: {
        dataItem: {
            type: Object,
            required: true
        }
    },
    emits: ["change"],
    setup(props, { emit }) {
        const option = ref(props.dataItem.name);
        const inputStyle = computed(() =>
            option.value.length > 0 ? "is-valid" : "is-invalid"
        );

        const onChange = (): void => {
            emit("change", option);
        };

        return {
            onChange,
            option,
            inputStyle
        };
    }
});
