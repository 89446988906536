import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.dataItem.inEdit)
    ? (_openBlock(), _createElementBlock("td", _hoisted_1, _toDisplayString(_ctx.dataItem.name), 1))
    : (_openBlock(), _createElementBlock("td", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass(["form-control", _ctx.inputStyle]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.option) = $event)),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 34), [
          [_vModelText, _ctx.option]
        ])
      ]))
}