import { GridColumnProps } from "@progress/kendo-vue-grid";

export const lessonDigitalBookPageColumns: Array<GridColumnProps> = [
    {
        field: "imageUrl",
        title: "Preview",
        filterable: false,
        sortable: false
    },
    {
        field: "sortOrder",
        title: "Sort Order"
    },
    {
        field: "edit",
        title: "Edit",
        width: 100,
        filterable: false,
        sortable: false
    },
    {
        title: "Delete",
        cell: "commandCell",
        filterable: false,
        sortable: false,
        width: 200
    }
];
