
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        dataItem: {
            type: Object,
            required: true
        }
    },
    emits: ["remove"],
    setup(props, { emit }) {
        const removeHandler = (): void => {
            emit("remove", props.dataItem);
        };

        return {
            removeHandler
        };
    }
});
