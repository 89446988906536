import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (!_ctx.dataItem.inEdit)
    ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
        (_ctx.dataItem.isCorrect)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              class: "text-green",
              icon: "check-circle",
              size: "lg"
            }))
          : (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 1,
              class: "text-danger",
              icon: "times-circle",
              size: "lg"
            }))
      ]))
    : (_openBlock(), _createElementBlock("td", _hoisted_2, [
        _createElementVNode("input", {
          class: "form-check-input",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
          type: "checkbox",
          checked: _ctx.dataItem.isCorrect
        }, null, 40, _hoisted_3)
      ]))
}