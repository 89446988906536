import { GridColumnProps } from "@progress/kendo-vue-grid";

export const emailTemplateColumns: Array<GridColumnProps> = [
    {
        field: "name",
        title: "Email Template Name"
    },
    {
        field: "edit",
        title: "Edit",
        width: 100,
        sortable: false
    }
];
