import { GridColumnProps } from "@progress/kendo-vue-grid";

export const emailsSentColumns: Array<GridColumnProps> = [
    { field: "to", title: "To" },
    {
        field: "from",
        title: "From"
    },
    {
        field: "subject",
        title: "Subject"
    },
    {
        field: "body",
        title: "Body"
    },
    {
        field: "insertedOn",
        title: "Date/Time",
        filter: "date"
    }
];
