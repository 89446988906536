
import {
    computed,
    defineComponent,
    PropType,
    reactive,
    ref,
    onMounted
} from "vue";
import { Form, FormActions } from "vee-validate";
import {
    getModelStateFromResponse,
    hasErrors,
    ValFormInput
} from "@elite/validation";
import { AnswerOptionModel, QuestionModel } from "@/models";
import { answerOptionColumns } from "@/columns";
import { AppGetterTypes, useStore } from "@/store";
import { AppActionTypes, restClient } from "@/store/actions";
import { formatDate, promptDelete } from "@/helpers";
import AnswerOptionGrid from "./answer-option-grid/AnswerOptionGrid.vue";
import { AnswerOptionGridItem } from "@/models/AnswerOptionGridItem";

export default defineComponent({
    props: {
        question: {
            type: Object as PropType<QuestionModel>,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        },
        quizId: {
            type: Number,
            required: true
        },

        /** ID for a grid to refresh after an add/edit is made */
        refreshGridId: {
            type: String,
            required: false,
            default: ""
        }
    },
    emits: ["handleClose"],
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "answer-option-grid": AnswerOptionGrid
    },
    setup(props, { emit }) {
        const { getters, state, dispatch } = useStore();
        const model: QuestionModel = reactive(props.question);
        const rules = computed(
            () => getters[AppGetterTypes.validationRules]?.questionModel
        );

        const gridValidationMessages = ref<string>();

        const isNew = model.id === 0;
        const isMultipleChoice = computed(
            () =>
                model.answerTypeId ===
                state.settings?.multipleChoiceAnswerTypeId
        );
        const answerTypes = computed(() => state.settings?.answerTypes);
        const inserted = computed(() =>
            model.insertedOn ? formatDate(model.insertedOn) : "-"
        );
        const updated = computed(() =>
            model.updatedOn ? formatDate(model.updatedOn) : "-"
        );
        const answerOptions = computed(
            () => model.answerOptions as Array<AnswerOptionGridItem>
        );
        const updatedAnswerOptions = ref<Array<AnswerOptionModel>>([]); // Used to track updated options from grid actions

        // Update with saved answer option values on mounted for comparison upon save
        onMounted(() => {
            updatedAnswerOptions.value = model.answerOptions ?? [];
        });

        const onSubmit = async (
            values: QuestionModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            const data = { ...model };

            // Remove any answer options if the question is no longer multiple choice
            if (!isMultipleChoice.value) {
                data.answerOptions = [];
            } else {
                // Validate that the length is greater than zero
                if (updatedAnswerOptions.value.length === 0) {
                    gridValidationMessages.value =
                        "There must be at least one answer option.";
                    return;
                }

                // Validate there is at least one correct answer
                if (
                    !updatedAnswerOptions.value.some(
                        (option) => option.isCorrect
                    )
                ) {
                    gridValidationMessages.value =
                        "There must be at least one correct answer.";

                    return;
                }
            }

            // If there are no validation errors, assign updated options to POST/PUT data
            if (
                isMultipleChoice.value &&
                updatedAnswerOptions.value !== model.answerOptions
            ) {
                data.answerOptions = updatedAnswerOptions.value;
            }

            // Save data
            const response = isNew
                ? await restClient.postJson<QuestionModel>(
                      "/api/admin/Question",
                      data
                  )
                : await restClient.putJson<QuestionModel>(
                      "/api/admin/Question",
                      data
                  );

            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
                return;
            }

            // Refresh a grid on the outside of this component after an add/edit happened
            if (props.refreshGridId) {
                await dispatch(AppActionTypes.refreshGrid, props.refreshGridId);
            }

            emit("handleClose");
        };

        // Prompt user to delete the question
        const handleDelete = async (): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete the question?"
                )
            ) {
                const response = await restClient.delete(
                    `/api/admin/Question/${model.id}`
                );

                if (hasErrors(response)) {
                    return;
                }

                emit("handleClose");
            }
        };

        // Emit event to close modal on cancel
        const handleCancel = (): void => {
            emit("handleClose");
        };

        // Update updatedAnswerOptions when new data is received from grid changes
        const handleAnswerOptionsChange = (
            answerOptions: Array<AnswerOptionGridItem>
        ): void => {
            updatedAnswerOptions.value = answerOptions;

            if (answerOptions.length > 0) {
                gridValidationMessages.value = undefined;
            }
        };

        return {
            onSubmit,
            model,
            rules,
            answerOptionColumns,
            handleDelete,
            isNew,
            inserted,
            updated,
            handleCancel,
            answerTypes,
            isMultipleChoice,
            answerOptions,
            handleAnswerOptionsChange,
            gridValidationMessages
        };
    }
});
