import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_cell = _resolveComponent("delete-cell")!
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_grid_no_records = _resolveComponent("grid-no-records")!
  const _component_grid = _resolveComponent("grid")!

  return (_openBlock(), _createBlock(_component_grid, {
    id: _ctx.id,
    "data-items": _ctx.dataItems,
    columns: _ctx.columns,
    pageable: _ctx.pageable,
    sortable: true,
    sort: _ctx.sort,
    filterable: _ctx.filterable,
    filter: _ctx.filter,
    total: _ctx.total,
    "page-size": _ctx.pageSize,
    take: _ctx.take,
    skip: _ctx.skip,
    onPagechange: _ctx.pageChanged,
    onSortchange: _ctx.sortChanged,
    onFilterchange: _ctx.filterChanged,
    "cell-render": 'template'
  }, {
    template: _withCtx(({ props, listeners }) => [
      _renderSlot(_ctx.$slots, "default", {
        props: props,
        listeners: listeners
      })
    ]),
    commandCell: _withCtx(({ props }) => [
      _createVNode(_component_delete_cell, {
        "data-item": props.dataItem,
        onRemove: _ctx.handleDelete
      }, null, 8, ["data-item", "onRemove"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_grid_no_records, null, {
        default: _withCtx(() => [
          (_ctx.dataItems === null)
            ? (_openBlock(), _createBlock(_component_loading_indicator, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, "There is no data."))
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["id", "data-items", "columns", "pageable", "sort", "filterable", "filter", "total", "page-size", "take", "skip", "onPagechange", "onSortchange", "onFilterchange"]))
}