
import { defineComponent, PropType, reactive, toRefs } from "vue";
import { GridVue3, GridNoRecordsVue3 } from "@progress/kendo-vue-grid";
import InlineEditCell from "./InlineEditCell.vue";
import IsCorrectCell from "./IsCorrectCell.vue";
import NameCell from "./NameCell.vue";
import { answerOptionColumns } from "@/columns";
import { promptDelete } from "@/helpers";
import { AnswerOptionGridState, AnswerOptionGridItem } from "@/models";

// Custom implementation adapted from Kendo docs
// See: https://www.telerik.com/kendo-vue-ui/components/grid/editing/editing-custom-template/
export default defineComponent({
    components: {
        "grid": GridVue3,
        "grid-no-records": GridNoRecordsVue3,
        "inline-edit-cell": InlineEditCell,
        "is-correct-cell": IsCorrectCell,
        "name-cell": NameCell
    },
    props: {
        initialData: {
            type: Array as PropType<AnswerOptionGridItem[]>,
            required: true
        }
    },
    emits: ["answerOptionsChange"],
    setup(props, { emit }) {
        const state: AnswerOptionGridState = reactive({
            dataItems: props.initialData,
            updatedData: [],
            itemInEdit: null,
            editedRowOriginalData: null,
            columns: answerOptionColumns
        });

        // Generate unique random negative integer to use as an ID for new rows
        const generateUniqueId = (): number => {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const random = Math.floor(Math.random() * 100) * -1;

                // If the ID is unique amongst entries, return it
                if (!state.dataItems.some((item) => item.id === random)) {
                    return random;
                }
            }
        };

        // Handle change in custom editor for isCorrect property
        const handleIsCorrectChange = (checked: boolean): void => {
            if (state.itemInEdit) {
                state.itemInEdit.isCorrect = checked;
            }
        };

        // Handle change in custom editor for name property
        const handleNameChange = (text: string): void => {
            if (state.itemInEdit) {
                state.itemInEdit.name = text;
            }
        };

        // Open inline editor in new row
        const addNew = (): void => {
            // Block action if an editor is already open
            if (state.itemInEdit) {
                return;
            }

            const emptyGridItem: AnswerOptionGridItem = {
                inEdit: true,
                isCorrect: false,
                name: ""
            };

            state.dataItems.unshift(emptyGridItem);
            state.itemInEdit = { ...emptyGridItem };
        };

        // Add new row
        const addItem = (): void => {
            if (!state.itemInEdit?.name) {
                return;
            }

            const newRow: AnswerOptionGridItem = {
                name: state.itemInEdit?.name,
                isCorrect: state.itemInEdit?.isCorrect,
                inEdit: false,
                id: generateUniqueId()
            };

            // Mark all other answers as incorrect if new option is correct
            if (newRow.isCorrect) {
                state.dataItems.forEach((item) => (item.isCorrect = false));
            }

            cancelEdit();
            state.dataItems.push(newRow);
            emit("answerOptionsChange", state.dataItems);
        };

        // Open inline editor
        const enterEdit = (item: AnswerOptionGridItem): void => {
            // Block action if an editor is already open
            if (state.itemInEdit) {
                return;
            }

            // Set backup copy of data that is used if the edit is cancelled
            state.editedRowOriginalData = { ...item };

            // Set copy of row that will be edited
            state.itemInEdit = { ...item };
            state.itemInEdit.inEdit = true;

            // Update data in state to include row currently in edit
            if (state.dataItems) {
                state.dataItems = state.dataItems.map((currentItem) => {
                    if (
                        state.itemInEdit &&
                        currentItem.id === state.itemInEdit.id
                    ) {
                        return state.itemInEdit;
                    }

                    return currentItem;
                });
            }
        };

        // Save item in edit
        const updateItem = (): void => {
            if (!state.itemInEdit?.name) {
                return;
            }

            state.dataItems = state.dataItems.map((row) => {
                if (state.itemInEdit && row.id === state.itemInEdit.id) {
                    row.name = state.itemInEdit.name;
                    row.isCorrect = state.itemInEdit.isCorrect;
                    row.inEdit = false;
                } else if (state.itemInEdit?.isCorrect) {
                    // Mark all other answers as incorrect if selected option has been updated to correct
                    row.isCorrect = false;
                }

                return row;
            });

            state.itemInEdit = null;
            state.editedRowOriginalData = null;
            emit("answerOptionsChange", state.dataItems);
        };

        // Remove item from grid
        const deleteItem = async (
            item: AnswerOptionGridItem
        ): Promise<void> => {
            const willDelete = await promptDelete(
                "Are you sure you want to delete this option?"
            );

            if (willDelete && item.id) {
                state.dataItems = state.dataItems.filter(
                    (e) => e.id !== item.id
                );

                emit("answerOptionsChange", state.dataItems);
            }
        };

        // Close inline editor, discard changes
        const cancelEdit = (): void => {
            state.itemInEdit = null;
            const data = state.dataItems;

            // Revert data
            const revertedData = data.map((record: AnswerOptionGridItem) => {
                if (state.editedRowOriginalData !== null) {
                    if (record.id === state.editedRowOriginalData.id) {
                        record = state.editedRowOriginalData;
                        record.inEdit = false;
                    } else {
                        record.inEdit = false;
                    }
                }

                return record;
            });

            // Remove inline edit row (does not have ID)
            const originalData = revertedData.filter((item) =>
                Object.keys(item).includes("id")
            );

            state.dataItems = originalData;
            state.editedRowOriginalData = null;
        };

        // Handle CRUD actions from grid
        const handleCRUD = (event: {
            operation: string;
            dataItem: AnswerOptionGridItem;
        }): void => {
            switch (event.operation) {
                case "edit":
                    enterEdit(event.dataItem);
                    break;
                case "delete":
                    deleteItem(event.dataItem);
                    break;
                case "update":
                    if (event.dataItem.id) {
                        updateItem();
                    } else {
                        addItem();
                    }
                    break;
                case "cancel":
                    cancelEdit();
                    break;
            }
        };

        return {
            ...toRefs(state),
            addNew,
            handleCRUD,
            handleIsCorrectChange,
            handleNameChange
        };
    }
});
