
import { defineComponent, onMounted, ref } from "vue";
import ContentCard from "@/components/ContentCard.vue";
import QuizForm from "@/components/QuizForm.vue";
import { QuizModel } from "@/models";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { restClient } from "@/store/actions";
import QuestionGrid from "@/components/QuestionGrid.vue";

export default defineComponent({
    components: {
        "content-card": ContentCard,
        "quiz-form": QuizForm,
        "loading-indicator": LoadingIndicator,
        "question-grid": QuestionGrid
    },
    props: {
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        },
        quizId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const model = ref<QuizModel>({});

        onMounted(async () => {
            if (props.quizId === 0) {
                model.value = {
                    id: 0,
                    lessonId: props.lessonId
                };
            } else {
                const response = await restClient.getJson<QuizModel>(
                    `/api/admin/Quiz/${props.quizId}`
                );

                if (response.data) {
                    model.value = response.data;
                }
            }
        });

        return { model };
    }
});
