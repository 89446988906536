import { GridColumnProps } from "@progress/kendo-vue-grid";

export const answerOptionColumns: Array<GridColumnProps> = [
    {
        cell: "nameCell",
        field: "name",
        title: "Option Name",
        filterable: false,
        sortable: false,
        editor: "text"
    },
    {
        cell: "isCorrectCell",
        field: "isCorrect",
        title: "Correct Answer",
        filterable: false,
        sortable: false,
        width: 150
    },
    {
        cell: "inlineEditCell",
        filterable: false,
        sortable: false,
        width: 200
    }
];
