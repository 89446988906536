import { GridColumnProps } from "@progress/kendo-vue-grid";

export const cancellationOfferColumns: Array<GridColumnProps> = [
    {
        field: "name",
        title: "Cancel/Save Offer Name"
    },
    {
        field: "status",
        title: "Status",
        type: "boolean"
    },
    {
        field: "edit",
        title: "Edit",
        width: 100,
        filterable: false,
        sortable: false
    },
    {
        title: "Delete",
        cell: "commandCell",
        filterable: false,
        sortable: false,
        width: 200
    }
];
