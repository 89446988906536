import { GridColumnProps } from "@progress/kendo-vue-grid";

export const progressLevelColumns: Array<GridColumnProps> = [
    {
        field: "name",
        title: "Progress Level Name"
    },
    {
        field: "image",
        width: 150,
        title: "Image",
        sortable: false
    },
    {
        field: "pointsToEarn",
        width: 160,
        title: "Points To Earn"
    },
    {
        field: "edit",
        title: "Edit",
        width: 100,
        filterable: false,
        sortable: false
    },
    {
        title: "Delete",
        cell: "commandCell",
        filterable: false,
        sortable: false,
        width: 200
    }
];
