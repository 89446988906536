
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        dataItem: {
            type: Object,
            required: true
        }
    },
    emits: ["edit", "remove", "save", "cancel"],
    setup(props, { emit }) {
        const editHandler = (): void => {
            emit("edit", { dataItem: props.dataItem, operation: "edit" });
        };

        const removeHandler = (): void => {
            emit("remove", {
                dataItem: props.dataItem,
                operation: "delete"
            });
        };

        const addUpdateHandler = (): void => {
            emit("save", {
                dataItem: props.dataItem,
                operation: "update"
            });
        };

        const cancelDiscardHandler = (): void => {
            emit("cancel", {
                dataItem: props.dataItem,
                operation: "cancel"
            });
        };

        return {
            editHandler,
            removeHandler,
            addUpdateHandler,
            cancelDiscardHandler
        };
    }
});
