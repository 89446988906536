
import { ChangeEvent } from "@progress/kendo-vue-dropdowns/dist/npm/common/events";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        dataItem: {
            type: Object,
            required: true
        }
    },
    emits: ["change"],
    setup(props, { emit }) {
        const onChange = (event: ChangeEvent): void => {
            emit("change", event.target.checked);
        };

        return {
            onChange
        };
    }
});
