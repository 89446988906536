import { GridColumnProps } from "@progress/kendo-vue-grid";

export const questionColumns: Array<GridColumnProps> = [
    {
        field: "name",
        title: "Question"
    },
    {
        field: "answerTypeDisplayName",
        title: "Answer Type"
    },
    {
        field: "edit",
        title: "Edit",
        width: 100,
        filterable: false,
        sortable: false
    },
    {
        title: "Delete",
        cell: "commandCell",
        filterable: false,
        sortable: false,
        width: 200
    }
];
