import { GridColumnProps } from "@progress/kendo-vue-grid";

export const cancellationBlackoutDateColumns: Array<GridColumnProps> = [
    {
        field: "blackoutDate",
        title: "Cancellation Blackout Date",
        type: "date"
    },
    {
        field: "edit",
        title: "Edit",
        width: 100,
        filterable: false,
        sortable: false
    },
    {
        title: "Delete",
        cell: "commandCell",
        filterable: false,
        sortable: false,
        width: 200
    }
];
